import React, { useContext, useEffect, useState } from "react";
import { CheckUUID } from "../../Utility/CheckUUID";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import "../../assets/css/loa.scss";
import { userInfo } from "../../Hooks/userInfo";
import { FormData } from "../../Utility/FormData";
const PreviewLoa = () => {
    const { isCheck, } = CheckUUID();
    const isCheckResponse = isCheck();
    const [showLoad, setshowLoad] = useState("show");
    const [modalShowState, setModalShowState] = useState(false);
    const [slideShow, setSlideShow] = useState('state.showSlide1');

    const [userName, setUserName] = useState();
    const [address, setAddress] = useState();
    const [joineeName, setJoineeName] = useState();
    const [userDob, setUserDob] = useState();
    const [joineeDob, setJoineeDob] = useState();
    const [joineeAddress, setJoineeAdress] = useState();
    const [currentDate, setCurrentDate] = useState();
    const [userSign, setUserSign] = useState();
    const [joineeSign, setJoineeSign] = useState();
    const [partnerDate, setPartnerDate] = useState();
    const [partnerSignShow, setPartnerSignShow] = useState('hide');
    const { getUserInfo } = userInfo();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const [currentUuid, setCurrentUuid] = useState();
    const [bankName, setBankName] = useState();
    const { setFormData } = FormData();
    const [userSortCode, setUserSortCode] = useState([]);
    const [userBankAccount, setUserBankAccount] = useState([]);
    const [previousAddress, setPreviousAddress] = useState();
    useEffect(() => {
        (async () => {
            const visitorData = visitorParameters.visitor_parameters;
            if (visitorData) {
                const uuid = visitorData.uuid;
                setCurrentUuid(uuid);
            }
        })();
    }, [visitorParameters]);
    let customUuid = '';
    let customSource = '';
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const query_source = query.get("source");
    const local_uuid = localStorage.getItem('uuid');
    customUuid = (query_uuid) ? query_uuid : local_uuid;
    customSource = (query_source) ? query_source : "live";

    useEffect(() => {
        const formdata = JSON.parse(localStorage.getItem('formData'));
        const customPreviousData = JSON.parse(localStorage.getItem('customPreviousData'));
        const userBank = localStorage.getItem('bankName');
        let question3 = (formdata) ? formdata.question_3 : '';
        if (formdata === undefined || formdata === null || formdata == '') {
            (async () => {
                const response = await getUserInfo(
                    customUuid
                );
                const set_formdata = setFormData(
                    response
                );
                let dataLength = (response.data.response).length;
                if (dataLength > 0) {
                    const userName = (response.data.response[0].first_name) ? Capitalize(response.data.response[0].first_name) + ' ' + Capitalize(response.data.response[0].last_name) : '';
                    const joineename = (response.data.response[0].spouses_first_name) ? Capitalize(response.data.response[0].spouses_first_name) + ' ' + Capitalize(response.data.response[0].spouses_last_name) : '';
                    const usersign = (response.data.response[0].signature_image) ? response.data.response[0].signature_image : '';
                    const userdob = (response.data.response[0].user_dob) ? response.data.response[0].user_dob : '';
                    const joineesign = (response.data.response[0].spouse_sign) ? response.data.response[0].spouse_sign : '';
                    const joineedob = (response.data.response[0].spouse_dob) ? response.data.response[0].spouse_dob : '';
                    let userAddress = '';
                    if (response.data.response[0].address_line1 !== '') {
                        userAddress = (response.data.response[0].address_line1) + ', ' + (response.data.response[0].town) + ', ' + (response.data.response[0].county) + ', ' + (response.data.response[0].country) + ', ' + (response.data.response[0].postcode).toUpperCase();
                    }
                    if (response.data.response[0].previous_address_city) {
                        let preAddress = ''
                        if (response.data.response[0].previous_address_line1 !== null) {
                            preAddress += response.data.response[0].previous_address_line1;
                        }
                        if (response.data.response[0].previous_address_line2 !== null) {
                            preAddress += ', ' + response.data.response[0].previous_address_line2;
                        }
                        if (response.data.response[0].previous_address_city !== null) {
                            preAddress += ', ' + response.data.response[0].previous_address_city;
                        }
                        if (response.data.response[0].previous_address_province !== null) {
                            preAddress += ', ' + response.data.response[0].previous_address_province;
                        }
                        if (response.data.response[0].previous_address_country !== null) {
                            preAddress += ', ' + response.data.response[0].previous_address_country;
                        }
                        if (response.data.response[0].previous_postcode !== null) {
                            preAddress += ', ' + (response.data.response[0].previous_postcode).toUpperCase();
                        }
                        //preAddress += ',' + response.data.response[0].previous_address_city + ',' + response.data.response[0].previous_address_province + ',' + response.data.response[0].previous_address_country + ',' + (response.data.response[0].previous_postcode).toUpperCase();
                        setPreviousAddress(preAddress);
                    }
                    const bankname = (response.data.response[0].bank_name) ? response.data.response[0].bank_name : null;
                    setBankName(bankname);
                    setUserName(userName);
                    setUserSign(usersign);
                    setUserDob(userdob);
                    setAddress(userAddress);
                    setJoineeName(joineename);
                    setJoineeDob(joineedob);
                    setJoineeSign(joineesign);
                    if (joineename === undefined || joineename === null || joineename === '') {
                        setJoineeAdress('');

                    } else {
                        setJoineeAdress(userAddress);
                    }
                    let timeNow = new Date().toLocaleString();
                    timeNow = (timeNow.split(' ')[0]).replace(/,/g, '');
                    if (joineename === undefined || joineename === null || joineename === '') {
                        setPartnerDate('');
                        setPartnerSignShow('hide');
                    } else {
                        setPartnerDate(timeNow);
                        setPartnerSignShow('show');
                    }
                    setCurrentDate(timeNow);
                }
            })();
        } else {
            const userName = (formdata) ? Capitalize(formdata.txtFName) + ' ' + Capitalize(formdata.txtLName) : '';
            // const joineeName = (formdata) ? Capitalize(formdata.jointFName) + ' ' + Capitalize(formdata.jointLName) : '';
            const userdob = (formdata) ? formdata.DobDay + '/' + formdata.DobMonth + '/' + formdata.DobYear : '';
            const joineedob = (formdata.JointDobDay) ? formdata.JointDobDay + '/' + formdata.JointDobMonth + '/' + formdata.JointDobYear : '';
            const userSign = (formdata) ? formdata.user_sign : '';
            let userAddress = '';
            if (formdata) {
                if (formdata.txtAddress3 !== '') {
                    userAddress = (formdata.txtHouseNumber + ', ' + formdata.txtAddress3 + ', ' + formdata.txtTown + ', ' + formdata.txtCounty + ', ' + formdata.txtCountry + ', ' + (formdata.txtPostCode).toUpperCase());
                } else {
                    userAddress = (formdata.txtHouseNumber + ', ' + formdata.txtTown + ', ' + formdata.txtCounty + ', ' + formdata.txtCountry + ', ' + (formdata.txtPostCode).toUpperCase());
                }
                if (customPreviousData) {
                    let preAddress = ''
                    if (customPreviousData.previous_address_city !== '' || customPreviousData.previous_address_city !== null) {
                        if (customPreviousData.previous_address_line1 !== null) {
                            preAddress += customPreviousData.previous_address_line1;
                        }
                        //  preAddress += customPreviousData.previous_address_line1;
                        if (customPreviousData.previous_address_line2 !== '') {
                            preAddress += ', ' + customPreviousData.previous_address_line2;
                        }
                        if (customPreviousData.previous_address_city !== '') {
                            preAddress += ', ' + customPreviousData.previous_address_city;
                        }
                        if (customPreviousData.previous_address_province !== '') {
                            preAddress += ', ' + customPreviousData.previous_address_province;
                        }
                        if (customPreviousData.previous_address_country !== '') {
                            preAddress += ', ' + customPreviousData.previous_address_country;
                        }
                        if (customPreviousData.previous_postcode_1 !== '') {

                            preAddress += ', ' + (customPreviousData.previous_postcode_1).toUpperCase();
                        }
                        //preAddress += ',' + customPreviousData.previous_address_city + ',' + customPreviousData.previous_address_province + ',' + customPreviousData.previous_address_country + ',' + (customPreviousData.previous_postcode_1).toUpperCase();
                        setPreviousAddress(preAddress);
                    }
                }
            }
            const partnerSign = (formdata) ? formdata.partner_sign : '';
            setBankName(userBank);
            setUserName(userName);
            setAddress(userAddress);
            setJoineeName(joineeName);
            setUserDob(userdob);
            setJoineeDob(joineedob);
            if (question3 == 5) {
                setJoineeAdress(userAddress);
                setJoineeSign(partnerSign);
            } else {
                setJoineeAdress('');
                setJoineeSign('');
            }
            setUserSign(userSign);
            let timeNow = new Date().toLocaleString();
            timeNow = (timeNow.split(' ')[0]).replace(/,/g, '');
            if (question3 == 5) {
                setPartnerDate(timeNow);
                setPartnerSignShow('show');
            } else {
                setPartnerDate('');
            }
            setCurrentDate(timeNow);
        }

        const localSortcode = localStorage.getItem('sort_code');
        if (localSortcode != undefined && localSortcode != 'null' && localSortcode != '' && localSortcode != 'Not In The List') {
            const l_SortCode = Array.from(String(localSortcode), String);
            setUserSortCode(l_SortCode);
            setModalShowState(false);

        }
        const localAccountNumber = localStorage.getItem('account_number');
        if (localAccountNumber != undefined && localAccountNumber != 'null' && localAccountNumber != '') {
            const l_AccountNumber = Array.from(String(localAccountNumber), String);
            setUserBankAccount(l_AccountNumber);
            setModalShowState(false);
        }
    }, [userName, userSign, userDob, address, joineeName, joineeDob, joineeSign, currentDate, joineeAddress, partnerDate, partnerSignShow]);

    function Capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return (
        <>
        <div className="loa-sec">
         <section className="">
            <div className="container pdf-section">
               <div className="row">
                  <div className="col-lg-6">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td><img src="/assets/img/logo.png" alt="" className="logo"/> </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-5 headtxt">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td width="10%">&nbsp;</td>
                              <td width="90%">
                                 <p>Lorem Ipsum is simply dummy text <br/>
                                    Lorem Ipsum is simply dummy text 
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 content_txt">
                     <h2>Lorem Ipsum is simply dummy text </h2>
                     <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                     </p>
                  </div>
                  <div className="col-12 content_txt">
                     <table width="100%" className="mtb25" border="1" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr className="brdr-btm">
                              <td className="text-center grey_bg">
                                 <h3>
                                    Lorem Ipsum is simply dummy text
                                 </h3>
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                       <tr>
                                          <td className="w90 bor-r">
                                             <h4> Company</h4>
                                          </td>
                                          <td >{bankName}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 <table className=" table-bordered" width="100%" border="1" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                       <tr>
                                          <td className="w90">
                                             <h4> Sort Code</h4>
                                          </td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userSortCode[0]} readOnly={true} /></td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userSortCode[1]} readOnly={true} /></td>
                                          <td className="text-center w25px">-</td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userSortCode[2]} readOnly={true} /></td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userSortCode[3]} readOnly={true} /></td>
                                          <td className="text-center w25px">-</td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userSortCode[4]} readOnly={true} /></td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userSortCode[5]} readOnly={true} /></td>
                                          <td  className="w90">
                                             <h4>  Account<br/>
                                                Number
                                             </h4>
                                          </td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userBankAccount[0]} readOnly={true} /></td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userBankAccount[1]} readOnly={true} /></td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userBankAccount[2]} readOnly={true} /></td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userBankAccount[3]} readOnly={true} /></td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userBankAccount[4]} readOnly={true} /></td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userBankAccount[5]} readOnly={true} /></td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userBankAccount[6]} readOnly={true} /></td>
                                          <td><input type="text" name="" id="" className="inp-cust" value={userBankAccount[7]} readOnly={true} /></td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     {/* <div className="col-lg-12 col-12 arrow-col">
                        <span className="arrow-div">
                        <span className="arrow-red">
                        <img src="dist/img/arrow-red.png" alt=""/>
                        </span>
                        Enter your Account number and Sortcode
                        </span> 
                     </div> */}
                  </div>
                  <div className="col-12 content_txt">
                     <h4 className="text-start"><strong> Lorem Ipsum is simply dummy text</strong></h4>
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="w35">1.</td>
                              <td>
                                 <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <h4  className="text-start"><strong>Lorem Ipsum is simply dummy text</strong> </h4>
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="w35">2.</td>
                              <td  className="padb5">
                                 <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td valign="top"  className="w35">3.</td>
                              <td className="padb5">
                                 <p> 
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td valign="top"  className="w35">4.</td>
                              <td  className="padb5">
                                 <p> 
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td valign="top"  className="w35">5.</td>
                              <td  className="padb5">
                                 <p> 
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td valign="top"  className="w35">6.</td>
                              <td  className="padb5">
                                 <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <table className="mtb10" width="100%" border="1" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td className="text-center grey_bg">
                                 <h3>Lorem Ipsum</h3>
                              </td>
                              <td className="text-center grey_bg">
                                 <h3>Lorem Ipsum</h3>
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 <table width="100%" border="1" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                       <tr>
                                          <td className="w100 pad5 brdr-btm">
                                             <p>Full Name</p>
                                          </td>
                                          <td className="brdr-bl">{userName}</td>
                                       </tr>
                                       <tr>
                                          <td  className="w100 h75 pad5 brdr-btm">
                                             <p>Address</p>
                                          </td>
                                          <td className="brdr-bl">{address}</td>
                                       </tr>
                                       <tr>
                                          <td  className="w100 pad5">
                                             <p>Date of Birth</p>
                                          </td>
                                          <td className="brdr-lf">{userDob}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </td>
                              <td>
                                 <table width="100%" border="1" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                       <tr>
                                          <td className="w100 pad5 brdr-btm">
                                             <p>Full Name</p>
                                          </td>
                                          <td className="brdr-bl">{joineeName}</td>
                                       </tr>
                                       <tr>
                                          <td  className="w100 h75 pad5 brdr-btm">
                                             <p>Address</p>
                                          </td>
                                          <td className="brdr-bl">{joineeAddress}</td>
                                       </tr>
                                       <tr>
                                          <td  className="w100 pad5">
                                             <p>Date of Birth</p>
                                          </td>
                                          <td className="brdr-lf">{joineeDob}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 <table width="100%" border="1" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                       <tr>
                                          <td valign="bottom" className="text-center h100"><img className="signImg" src={` ${userSign}`} /></td>
                                          <td valign="bottom" className="text-center h100 brdr-lf">{currentDate}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </td>
                              <td>
                                 <table width="100%" border="1" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                       <tr>
                                          {/* <td valign="bottom" className="text-center h100">
                                             <img className="signImg" src={joineeSign} />
                                             </td> */}
                                          <td valign="bottom" className="text-center h100 brdr-lf">{currentDate}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <h3><strong>  Lorem Ipsum is simply dummy text</strong></h3>
                     <p className="redtext">
                        <strong> 
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                        </strong>
                     </p>
                     <p><strong> 
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        </strong></p>
                     <div className="row mtop25">
                        <div className="col-7 text_small">
                           <p><small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </small></p>
                           <p><small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </small></p>
                           <p><small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  </small></p>
                        </div>
                        <div className="col-5 arrowbg">
                           <p><b>Lorem Ipsum is simply dummy text </b></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="container pdf-section">
               <div className="row">
                  <div className="col-12 content_txt">
                     <div className="col-12 name-div"></div>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <h2>Lorem Ipsum is simply dummy text </h2>
                     <h3 className="padb10"><strong>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text 
                        </strong>
                     </h3>
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="w35">1.</td>
                              <td  className="padb10">
                                 <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td valign="top"  className="w35">2.</td>
                              <td className="padb10">
                                 <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td valign="top"  className="w35">3.</td>
                              <td  className="padb10">
                                 <p> 
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td valign="top"  className="w35">4.</td>
                              <td  className="padb10">
                                 <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <div className="row">
                        <div className="col-5">
                           <div className="col-12 name-div">
                                {userName}
                           </div>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-5">
                           <div className="col-12 name-div">
                                {joineeName}
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-5">
                           <div className="col-10 name-div">
                              {currentDate}
                           </div>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-5">
                           <div className="col-10 name-div">
                                {currentDate}
                           </div>
                        </div>
                     </div>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <h3><strong>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  </strong></h3>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                     <p>&nbsp;</p>
                  </div>
               </div>
            </div>
            <div className="container ">
               <div className="col-12 col-lg-12 text-center">
                  <a href={'/thankyou?uuid=' + customUuid} className="button button-next animated animated-effect">NEXT</a>
               </div>
            </div>
         </section>
      </div>  
        </>
    )
}
export default PreviewLoa;