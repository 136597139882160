import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import "./App.scss";
import ADV1_Contact from "./Components/Pages/Advertorials/ADV1_Contact";
import ADV_1 from "./Components/Pages/Advertorials/ADV_1";
import FbPixel from "./Components/Pages/FbPixel";
import HomePage from "./Components/Pages/HomePage";
import IdUpload from "./Components/Pages/IdUpload";
import NotFound from "./Components/Pages/NotFound";
import Preview from "./Components/Pages/Preview";
import PreviewLoa from "./Components/Pages/PreviewLoa";
import Proceed from "./Components/Pages/Proceed";
import Questionnaire from "./Components/Pages/Questionnaire";
import Signature from "./Components/Pages/Signature";
import Followup from "./Components/Pages/Splits/Followup";
import Split_1 from "./Components/Pages/Splits/Split_1";
import Thankyou from "./Components/Pages/Thankyou";
import Unqualified from "./Components/Pages/Unqualified";
import { QueryStringProvider } from "./Contexts/QueryString";
import { RedirectProvider } from "./Contexts/RedirectContext";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";

const CMLJ_V1 = lazy(() => import("./Components/Pages/CMLJ_V1"));

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: "" });

  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);

  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <Suspense fallback="Loading...">
              <BrowserRouter>
                <Route exact path="/" component={HomePage} />
                <Route
                  exact
                  path={["/CMLJ_V1"]}
                  component={CMLJ_V1}
                />
                <Route exact path={["/Split_1"]} component={Split_1} />
                <Route
                  exact
                  path={"/questionnaire"}
                  component={Questionnaire}
                />
                <Route exact path={"/followup"} component={Followup} />
                <Route exact path={["/splash-page"]} component={Preview} />
                <Route exact path={"/proceed"} component={Proceed} />
                <Route exact path={"/unqualified"} component={Unqualified} />
                <Route exact path={"/signature"} component={Signature} />
                <Route exact path={["/fbpixel"]} component={FbPixel} />
                <Route exact path={"/thankyou"} component={Thankyou} />
                <Route exact path={["/id-upload"]} component={IdUpload} />
                <Route exact path={["/preview"]} component={PreviewLoa} />
                <Route exact path={["/404"]} component={NotFound} />
                <Route exact path={["/ADV_1"]} component={ADV_1} />
                <Route
                  exact
                  path={["/ADV1/contact"]}
                  component={ADV1_Contact}
                />
              </BrowserRouter>
            </Suspense>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
