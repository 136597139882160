//import {useState} from 'react';
import { useCallback } from "react";
import { CommonEndApi } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

export const usePostCodeAddress = () => {
  const fetch = async (path, query = {}, options = {}) => {
    query = {
      ...query,
      ProjectCode: EnvConstants.AppAdtopiaUPID,
      Environment: EnvConstants.AppEnv,
    };

    return await CommonEndApi.get(
      `${path}?${new URLSearchParams(query)}`,
      options
    );
  };

  const getPostcodeAddress = useCallback((postcode) => {
    return fetch("api/get-addr-list", { postcode });
  }, []);

  const getSplitPostcodeAddress = useCallback((addressId) => {
    return fetch("api/get-addr-details", { addressId });
  }, []);

  return { getPostcodeAddress, getSplitPostcodeAddress };
};
