import React ,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../assets/Thankyou/css/Thankyou.scss";
const Thankyou = () => {
  localStorage.clear();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const modalClick = (clickValue) => {
    switch (clickValue) {
     
      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showCookie": {
        setShowCookie(true);
        break;
      }
      case "exitCookie": {
        setShowCookie(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
    }
  };
  return (
    <>
      <header>
         <div className="container">
            <div className="col-xl-12 text-center">
                  <img src="/assets/Thankyou/img/logo-white.png" alt=""/>
            </div>
         </div>
      </header>

      <section>
         <div className="container">
            <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 text-center">

               <h5 className="mb-3">
                  THANK YOU!
               </h5>

               <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
               </p>
                  
            </div>
         </div>
      </section>


      <footer className="fixd-ftr">
         <div className="container">
            <div className="col-xl-8 col-lg-8 text-start">
               <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
               <ul>
                  <li><a href="#" data-bs-toggle="modal" onClick={() => modalClick("showPrivacy")}>Privacy and Cookie Policy</a></li>
                  <li><a href="#" data-bs-toggle="modal" onClick={() => modalClick("showCookie")}>Terms and Conditions</a></li>
                  <li><a href="#" data-bs-toggle="modal" onClick={() => modalClick("showComplaint")}> Complaints Procedure</a></li>
               </ul>
            </div>
         </div>
      </footer>
      <Modal show={showPrivacy} size="xl" id="privacy"  className="modal fade">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">PRIVACY AND COOKIE POLICY</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={() => modalClick("exitPrivacy")}></button>
              </div>
              <div className="modal-body">
                <h3>Privacy Policy</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  onClick={() => modalClick("exitPrivacy")}>Close</button>
              </div>
            </div>
        </Modal>
        <Modal show={showCookie} size="xl" className="modal fade" id="terms">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Terms and Conditions</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => modalClick("exitCookie")}></button>
              </div>
              <div className="modal-body">
                <h3>Terms and Conditions</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => modalClick("exitCookie")}>Close</button>
              </div>
            </div>
        </Modal>
        <Modal show={showComplaint} size="xl" className="modal fade" id="complaints">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">COMPLAINTS PROCEDURE</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => modalClick("exitComplaint")}></button>
              </div>
              <div className="modal-body">
                <h3>Complaints Procedure</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => modalClick("exitComplaint")}>Close</button>
              </div>
            </div>
        </Modal>
    </>
  )
}

export default Thankyou;
